let Config = {
    PERSIST_SECRET_KEY: "RC_WEB_SECRET_KEY",
    ENVIRONMENT: "DEVELOPMENT",
    ENVIRONMENTS: {
        LOCAL: {
            API_URL: "https://dev-api-connect.digitalrange.com",
            BASE_IMG_PATH: "https://s3.amazonaws.com/admin.digitalrange.dev",
            SHORTEN_URL_DOMAIN: "https://dev-clubch.at",
            WEBCHAT_URL_DOMAIN: "https://dev-webchat.digitalrange.com/",
            WEBCHAT_APP_DOMAIN: "RC_WEB_WEBCHAT_APP_DOMAIN"
        },
        DEVELOPMENT: {
            API_URL: "https://dev-api-connect.digitalrange.com",
            BASE_IMG_PATH: "https://s3.amazonaws.com/admin.digitalrange.dev",
            SHORTEN_URL_DOMAIN: "https://dev-clubch.at",
            WEBCHAT_URL_DOMAIN: "https://dev-webchat.digitalrange.com/",
            WEBCHAT_APP_DOMAIN: "RC_WEB_WEBCHAT_APP_DOMAIN",
            WIDGET_URL_DOMAIN: "https://dev-widget.digitalrange.com/"
        },
        PRODUCTION: {
            API_URL: "https://dev-api-connect.digitalrange.com",
            BASE_IMG_PATH: "https://s3.amazonaws.com/admin.digitalrange.dev",
            SHORTEN_URL_DOMAIN: "https://dev-clubch.at",
            WEBCHAT_URL_DOMAIN: "https://dev-webchat.digitalrange.com/",
            WEBCHAT_APP_DOMAIN: "RC_WEB_WEBCHAT_APP_DOMAIN",
            WIDGET_URL_DOMAIN: "https://dev-widget.digitalrange.com/"
        }
    }
};

Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};

Config.envName = () => Config.ENVIRONMENT;

export default Config;